<template>
    <v-col cols="12">
        <div v-if="type !== 'map'">
            <v-card
            v-for="hotel in hotels.Hotels"
            :key="hotel.HotelIndex"
            class="mb-5"
            >
                <v-row>
                    <v-col cols="12" md="4" class="py-0">
                        <v-img height="100%" :alt="hotel.HotelName" class="image rounded-l" :src="hotel.HotelPicture"></v-img>
                    </v-col>
                    <v-col cols="12" md="8" class="pl-0 pb-0">
                        <v-card-title
                        class="py-0"
                        >
                            <v-row no-gutters>
                                <v-col cols="12" md="8">
                                    <div class="blueDark--text body-1 font-weight-bold pt-0" style="word-break: break-word;">
                                        {{hotel.HotelName}}
                                    </div>
                                    <span class="pl-0 caption grey--text"><v-icon left>mdi-map-marker</v-icon> {{hotel.HotelCityName}}</span>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <div class="text-right">
                                        <v-rating class="mt-2 mb-0 mr-3" dense :length="hotel.stars" color="warning" readonly x-small  :value="parseInt(hotel.stars)" full-icon="mdi-star"></v-rating>
                                        <span class="secondary--text body-1" v-if="hotel.TripAdviserRating">Ranting: {{hotel.TripAdviserRating}}</span>
                                        <v-btn text color="blueDark" class="" small @click="openMap(hotel.HotelIndex, hotel.HotelOnMap)">
                                            <v-icon color="blueDark">mdi-map-search</v-icon> Show on map
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-title>

                        <v-card-text>
                            <div>
                                <v-subheader class="pl-0">{{getNights()}} Nights, {{getGuestsNum()}} Guests <br> {{hotel.pricingInfo.pricePerNight.toFixed(2) | changeCurrency}} /Night</v-subheader>
                                <p class="text-h5 font-weight-bold orange--text">{{hotel.pricingInfo.totalPrice.toFixed(2) | changeCurrency}}</p>
                                <p class="body-2"></p>
                                <!-- <p class="pl-0 text-truncate" v-html="hotel.HotelDescription"></p> -->
                                <div class="hello">
                                    <read-more more-str="read more" :text="hotel.HotelDescription" link="#" less-str="read less" :max-chars="150"></read-more>
                                    <!-- <read-more more-str="read more" less-str="read less" :text="hotel.HotelDescription" link="#"></read-more> -->
                                </div>
                            </div>
                            <v-btn
                            tile
                            color="orange white--text"
                            elevation="0"
                            class="rounded-lg mt-3"
                            @click="select(hotel.HotelIndex, hotel.HotelCode)"
                            >
                                See availability
                            </v-btn>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card>

            <v-dialog
            v-model="dialog1"
            max-width="80%"
            >
                <gmap-map
                :center="center"
                :zoom="16"
                style="width:100%;  height: 700px;"
                zoomControl
                class="mt-1"
                >
                    <gmap-marker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    @click="center=m.position"
                    :clickable="true"
                    :draggable="true"
                    ></gmap-marker>
                </gmap-map>
            </v-dialog>
        </div>

        <v-card v-if="type === 'map' && hotel">
            <span>
                <img v-if="hotel && hotel.HotelName" height="200" width="100%" :alt="hotel.HotelName" :src="hotel.HotelPicture"/>
            </span>

            <v-card-title class="font-weight-bolder" v-if="hotel && hotel.HotelName">{{hotel.HotelName}}</v-card-title>

            <v-card-text>
            <v-row
                align="center"
                class="mx-0"
            >
                <v-rating
                :value="parseInt(hotel.stars)"
                color="warning"
                readonly
                small
                :length="hotel.stars"
                ></v-rating>

                <div v-if="hotel.TripAdviserUrl">
                    <v-avatar color="indigo" size="36">
                        <span class="white--text headline">{{hotel.TripAdviserRating}}</span>
                    </v-avatar>
                </div>
            </v-row>

            <div>{{hotel.HotelDescription}}</div>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <v-card-title class="font-weight-bold">{{hotel.pricingInfo.totalPrice.toFixed(2)  | changeCurrency}}</v-card-title>

            <v-card-text>
                {{getNights()}}Nights, {{hotels.roomGuests.Adults + hotels.roomGuests.Children}} Guests
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                tile
                class="orange white--text"
                @click="select(hotel.HotelIndex, hotel.HotelCode, $store.state.hotelSearch.guestsInfo.length)"
                >
                    See availability
                    <v-icon small right color="white">mdi-chevron-right</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
export default {
  props: ['hotels', 'session', 'type', 'hotel'],
  data () {
    return {
      showPlaceholderImage: false,
      src: '',
      center: {
        lat: 0,
        lng: 0
      },
      markers: [],
      dialog: false,
      dialog1: false,
      now: null
    }
  },
  watch: {
    'this.$store.state.hotels' (newVal) {
      this.hotels = newVal
    },
    hotels () {
      this.now = Date.now()
    }
  },
  methods: {
    openMap (index, position) {
      this.dialog1 = !this.dialog1
      this.center.lat = parseFloat(position.Latitude)
      this.center.lng = parseFloat(position.Longitude)
      this.markers.push({ position: this.center })
    },
    getPosition (position) {
      this.center.lat = parseFloat(position.Latitude)
      this.center.lng = parseFloat(position.Longitude)
      this.markers.push({ position: this.center })
    },
    getNights () {
      const checkIn = new Date(this.$store.state.hotelSearch.checkInDate)
      const checkOut = new Date(this.$store.state.hotelSearch.checkOutDate)
      const DifferenceInTime = checkOut.getTime() - checkIn.getTime()
      return DifferenceInTime / (1000 * 3600 * 24)
    },
    getGuestsNum () {
      let adults = 0
      let children = 0
      for (let index = 0; index < this.hotels.roomGuests.length; index++) {
        adults += this.hotels.roomGuests[index].Adults
        children += this.hotels.roomGuests[index].Children
      }
      return adults + children
    },
    select (index, code, rooms) {
      const spentTime = Date.now() - this.now
      const timer = (20 * 60 * 1000) - spentTime
      localStorage.setItem('hotelsTimer', timer)
      const routeData = this.$router.resolve({ name: 'oneHotel', params: { sessionId: this.session, hotelIndex: index, hotelCode: code, roomsNo: rooms } })
      window.open(routeData.href, '_blank')
    }
  },
  created () {
    this.now = Date.now()
  }
}
</script>
